import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="76"
        class="elementor elementor-76"
        data-elementor-post-type="page"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-395cce4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="395cce4"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7ebe3fe animated fadeInUp"
              data-id="7ebe3fe"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-e70769d elementor-widget elementor-widget-heading"
                  data-id="e70769d"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-a02d03e elementor-widget elementor-widget-text-editor"
                  data-id="a02d03e"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by&nbsp;First Rate Auto
                      Warranty&nbsp;(“we,” “us,” or “our”)
                      on&nbsp;firstrateautowarranty.com
                      (the&nbsp;“Site”)&nbsp;is for general informational
                      purposes only. All information on&nbsp;the Site&nbsp;is
                      provided in good faith, however we make no representation
                      or warranty of any kind, express or implied, regarding the
                      accuracy, adequacy, validity, reliability, availability,
                      or completeness of any information on&nbsp;the Site. UNDER
                      NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                      LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                      OF&nbsp;THE SITE&nbsp;OR RELIANCE ON ANY INFORMATION
                      PROVIDED ON&nbsp;THE SITE. YOUR USE OF&nbsp;THE
                      SITE&nbsp;AND YOUR RELIANCE ON ANY INFORMATION ON&nbsp;THE
                      SITE&nbsp;IS SOLELY AT YOUR OWN RISK.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-81800c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="81800c9"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9d8a920 animated fadeInUp"
              data-id="9d8a920"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-f9f0117 elementor-widget elementor-widget-heading"
                  data-id="f9f0117"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      PROFESSIONAL DISCLAIMER
                    </h4>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-895c9a7 elementor-widget elementor-widget-text-editor"
                  data-id="895c9a7"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The Site cannot and does not contain&nbsp;Auto
                      Warranty&nbsp;advice. The&nbsp;Auto
                      Warranty&nbsp;information is provided for general
                      informational and educational purposes only and is not a
                      substitute for professional advice. Accordingly, before
                      taking any actions based upon such information, we
                      encourage you to consult with the appropriate
                      professionals. We do not provide any kind of&nbsp;Auto
                      Warranty&nbsp;advice.&nbsp;THE USE OR RELIANCE OF ANY
                      INFORMATION CONTAINED ON&nbsp;THE SITE&nbsp;IS SOLELY AT
                      YOUR OWN RISK.
                    </p>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-297aa63 elementor-widget elementor-widget-heading"
                  data-id="297aa63"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      TCPA DISCLAIMER
                    </h4>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-6cf4523 elementor-widget elementor-widget-text-editor"
                  data-id="6cf4523"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by FIRST
                      RATE AUTO WARRANTY, our agents, representatives,
                      affiliates, or anyone calling on our behalf. This is for
                      any purposes relating to your request for Auto Warranty
                      service, at any telephone number, or physical or
                      electronic address you provide or at which you may be
                      reached. You agree we may contact you in any way,
                      including SMS messages (text messages), calls using
                      prerecorded messages or artificial voice, and calls and
                      messages delivered using auto telephone dialing system or
                      an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered, whether by you or someone else. In the event
                      that an agent or representative of FIRST RATE AUTO
                      WARRANTY calls, he or she may also leave a message on your
                      answering machine, voice mail, or send one via text. You
                      certify that the telephone numbers that you have provided,
                      are in fact your contact numbers, and you agree to receive
                      calls at each phone number you have provided FIRST RATE
                      AUTO WARRANTY. You agree to promptly alert FIRST RATE AUTO
                      WARRANTY in the event that you stop using a particular
                      phone number. Your cell/mobile telephone provider will
                      charge you according the type of account you carry with
                      those companies. You may opt out of any future contact via
                      text message by replying anytime with “STOP”. You agree
                      that FIRST RATE AUTO WARRANTY may contact you by email,
                      using any email address you have provided or that you
                      provide in the future. You may opt out of any future
                      contact via email message by replying anytime with
                      “UNSUBSCRIBE”. FIRST RATE AUTO WARRANTY may listen to
                      and/or record calls between you and any representative
                      without notice, as permitted by applicable laws. For
                      example we listen to calls to monitor for quality
                      purposes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
