import React from "react";
import Image from "../assets/images/footer-top-bg1-1.png";
export default function Alert({AlertSection}) {
  return (
    <div className="Alert">
      <div
        class="footer-top-1 bg-theme"
        style={{ backgroundImage: `url(${Image})`, backgroundPosition: `right` }}
      >
        <h2>How We Can Help</h2>
        <p>
         {AlertSection.Text}
        </p>
      </div>
    </div>
  );
}
