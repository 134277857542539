import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_bng_lps";
import HowItWorks from "../components/HowItWorks";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Image from "../assets/images/hero7.webp";
import Image1 from "../assets/images/hero8.webp";
import Alert from "../components/Alert";
import Image00 from '../assets/images/service-1-1.png';
import Image11 from '../assets/images/1.webp';
import Image22 from '../assets/images/2.webp';
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_bng_lps.js");
  const HeroSlides = [
    {
      backgroundImage: Image,
      subtitle: `Extend Your  Warranty  </br>
Reduce Your </br> Car Repair Bills`,
      text: "Get a Customized Auto Warranty for Your Needs",
    },
    {
      backgroundImage: Image1,
      subtitle: `Extend Your  Warranty  </br>
      Reduce Your </br> Car Repair Bills`,
            text: "Get a Customized Auto Warranty for Your Needs",
    },
  ];
  const contact = {
    Heading: `Your Perfect Auto Warranty <br/>
is a Call Away`,
  };
  const AlertSection = {
    Text: `Our mission is to help you find the best coverage for your vehicle, ensuring peace of mind and financial protection on the road.`,
  };
  const services = [
    {
      title: "Flexible Pricing Plans",
      description: "Discover flexible pricing plans that make it easy to find the perfect coverage for your needs and budget. Explore a wide range of options to get the protection you need without breaking the bank.",
      imgSrc: Image11,
     
    },
    {
      title: "Comprehensive Coverage",
      description: "Choose from comprehensive coverage plans that protect you from almost any repair. Whether it’s something small or a major problem, you can find the right plan to cover your needs.",
      imgSrc: Image22,
      
    },
    {
      title: "Nationwide Network of Certified Repair Shops",
      description: `Benefit from access to a nationwide network of certified car repair shops. No matter where you are, you can get connected to professionals who can help you get your car fixed quickly and easily.`,
      imgSrc: Image00,
     
    }
  ];
  const PhoneNumber = {
    PhoneNo: "(855) 745-3194",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slides={HeroSlides} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      
      <Services  services={services} PhoneNumber={PhoneNumber} />
      <Alert AlertSection={AlertSection} />
      <Contact contact={contact} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
