import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { EffectFade, Autoplay } from "swiper/modules";

import Image3 from "../assets/images/hero_shape_3.png";

export default function HeroSection({ PhoneNumber, slides }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;



  return (
    <div className="HeroSection">
      <div className="hero-wrapper hero-3">
        <div className="hero-3-slider global-carousel">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop
            effect="fade"
            autoplay={{ delay: 5000 }}
            watchSlidesProgress
            modules={[EffectFade, Autoplay]}
            className="swiper"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div
                  className="hero-slide"
                  style={{ backgroundImage: `url(${slide.backgroundImage})` }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-5 col-lg-6">
                        <div className="hero-style3">
                          <div
                            className="hero-subtitle text-white"
                            data-ani="slideinup"
                            data-ani-delay="0s"
                          >
                            <span>
                              <img src={Image3} alt="" />
                              
                              <p
                      className="sec-title text-white mb-0"
                      dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                    />
                            </span>
                          </div>

                          <p
                            className="hero-text text-white"
                            data-ani="slideinup"
                            data-ani-delay="0.2s"
                          >
                            {slide.text}
                          </p>
                          <div className="btn-group">
                            <a href={`tel:${telPhoneNumber}`}>
                              CALL NOW: {PhoneNumber.PhoneNo}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
