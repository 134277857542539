import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Alert from "./components/Alert";

import Image from "./assets/images/hero1.webp";
import Image1 from "./assets/images/hero2.webp";

import Image00 from './assets/images/service-1-1.png';
import Image11 from './assets/images/1.webp';
import Image22 from './assets/images/2.webp';
export default function landingPage() {
  const HeroSlides = [
    {
      backgroundImage: Image,
      subtitle: `Your Gateway  To</br> Reliable </br> Auto Warranty Services`,
      text: "Discover the Best Auto Warranty Plans Today",
    },
    {
      backgroundImage: Image1,
      subtitle: `Your Gateway To</br> Reliable </br> Auto Warranty Services`,
      text: "Discover the Best Auto Warranty Plans Today",
    },
  ];
  const contact = {
    Heading: `Secure Your Vehicle 
with <br /> Top-Notch Protection`,
  };
  const AlertSection = {
    Text: `We specialize in connecting you with leading auto warranty providers.
          Our mission is to help you find the best coverage for your vehicle,
          ensuring peace of mind and financial protection on the road. Explore a
          range of options tailored to meet your specific needs and budget.`,
  };
  const services = [
    {
      title: "Flexible Pricing Plans",
      description: "Discover flexible pricing plans that make it easy to find the perfect coverage for your needs and budget. Explore a wide range of options to get the protection you need without breaking the bank.",
      imgSrc: Image11,
     
    },
    {
      title: "Comprehensive Coverage",
      description: "Choose from comprehensive coverage plans that protect you from almost any repair. Whether it’s something small or a major problem, you can find the right plan to cover your needs.",
      imgSrc: Image22,
      
    },
    {
      title: "Nationwide Network of Certified Repair Shops",
      description: `Benefit from access to a nationwide network of certified car repair shops. No matter where you are, you can get connected to professionals who can help you get your car fixed quickly and easily. 
      Don't let the complexity of auto warranties keep you from securing the best protection for your vehicle. Get access to the industry's top providers, making it easier than ever to find the perfect plan. Start your journey with us today and drive with confidence.`,
      imgSrc: Image00,
     
    }
  ];
  const PhoneNumber = {
    PhoneNo: "(877) 579-1058",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slides={HeroSlides} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <Services  services={services} PhoneNumber={PhoneNumber} />
      <Alert AlertSection={AlertSection} />
      <Contact contact={contact} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
