import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Image1 from '../assets/images/wcu-3.png';
import Image2 from '../assets/images/about_shape1-1.svg';
import Image3 from '../assets/images/about_shape1-2.svg';
export default function HowItWorks({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="HowWorks">
      <div class="about-area-1 space">
        <div class="about1-shape-img shape-mockup">
          <img
            class="about1-shape-img-1 spin"
            src={Image3}
            alt="Fixturbo"
          />
          <img
            class="about1-shape-img-2 spin2"
            src={Image2}
            alt="Fixturbo"
          />
        </div>
        <div class="container">
          <div class="row gx-60 align-items-center flex-row-reverse">
           
            <div class="col-xl-5">
              <div class="about-content-wrap">
                <div class="title-area me-xl-5 mb-30">
                  {/* <span class="sub-title">Know About Us</span> */}
                  <h2 class="sec-title">Get The Protection Your Vehicle Deserves</h2>
                  <p class="sec-text">
                  We only work with the best in the business to ensure that you’re getting the highest quality coverage available. With First Rate Auto Warranty, you’ll get:
                  </p>
                </div>
                <div class="row gy-4 justify-content-md-between justify-content-end align-items-center flex-row-reverse">
                  <div class="col-md-auto">
                    <div class="checklist style2">
                      <ul>
                        <li>
                          <i class="fas fa-check-double"></i>Affordable car warranty prices that work with most budgets
                        </li>
                        <li>
                          <i class="fas fa-check-double"></i>Longer warranty period that covers more car repairs
                        </li>
                        <li>
                          <i class="fas fa-check-double"></i>Nationwide coverage so you can get your car repaired almost anywhere
                        </li>
                        <li>
                          <i class="fas fa-check-double"></i>A wide network of certified repair shops
                        </li>
                        <li>
                          <i class="fas fa-check-double"></i>Flexible payment plans to make it easier on your wallet
                        </li>
                        <li>
                          <i class="fas fa-check-double"></i>Comprehensive car coverage options
                        </li>
                      </ul>
                    </div>
                  </div>
                
                </div>
                <div class="btn-group mt-40">
                  <a class="btn style2" href="/about">
                    CALL NOW: (877) 579-1058 <i class="fas fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-7 text-xl-center">
              <div class="about-thumb3 mb-40 mb-xl-0">
                <div class="about-img-1">
                  <img className="" src={Image1} alt="Fixturbo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
