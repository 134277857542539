import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Icon from "../assets/images/service-icon_1-3.svg"
export default function Services({ PhoneNumber, services }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  

  return (
    <div className="Services">
      <h2>What we offer</h2>
      <div className="serviceFlex">
        {services.map((service, index) => (
          <div className="service-card" ref={cardRefs[index]} key={index}>
            <div className="service-card_content">
              {/* <div className="service-card_icon">
                <img src={service.iconSrc} alt={service.title} />
              </div> */}
              <h4 className="service-card_title h5">
                <a href={service.link}>{service.title}</a>
              </h4>
              <p className="service-card_text">{service.description}</p>
              
            </div>
            <div className="service-card_img">
              <img src={service.imgSrc} alt={service.title} />
            </div>
          </div>
        ))}
      </div>
      <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
    </div>
  );
}
