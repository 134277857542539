import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Image from "../assets/images/cta-bg1-1.png";
export default function Contact({ PhoneNumber, contact }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Contact">
      <div className="cta-area-1">
        <div className="cta1-bg-thumb">
          <img src={Image} alt="Fixturbo" />
        </div>
        <div className="container">
          <div className="cta-wrap1">
            <div className="row justify-content-md-between align-items-center">
              <div className="make">
                <div className="col-lg-6 col-md-8">
                  <div className="title-area mb-md-0">
                    <span className="sub-title style2 text-white">
                      Contact us
                    </span>
                    <h2
                      className="sec-title text-white mb-0"
                      dangerouslySetInnerHTML={{ __html: contact.Heading }}
                    />
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="btn-group">
                    <a href={`tel:${telPhoneNumber}`}>
                      {" "}
                      CALL NOW:{PhoneNumber.PhoneNo}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
